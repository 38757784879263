import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import ReactJS from "../Assests/logo192.png";
import angular from "../Assests/angular.png";
import node from "../Assests/node.png";
import python from "../Assests/python.png";
import flutter from "../Assests/flutter.webp";
import mysql from "../Assests/mysql.png";
import mongo from "../Assests/mongo.webp";
import spring from "../Assests/spring-boot.png";
import django from "../Assests/django.png";
import java from "../Assests/java.webp";
import aws from "../Assests/aws.png";
import c from "../Assests/c.webp";
import php from "../Assests/php.png";
import all from "../Assests/all.png";
import golang from '../Assests/golang.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    height: 100,
  },
  rotation: {
    transform: 'rotateY(0deg) rotateX(0deg)',
    transform: "translateZ(200px)"
  }
}));

export default function SpacingGrid() {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  const [hover, setHover] = useState(null);

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center"onMouseEnter={()=>{
          console.log("ReactJs");
          setHover("ReactJs");
        }}>
          <Tooltip title="ReactJS">
            <Tooltip title="ReactJS">
              <img src={ReactJS} className={classes.image}></img>
            </Tooltip>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Angular">
            <img src={angular} className={classes.image}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Node.JS">
            <img
              src={node}
              className={classes.image}
              Style="height:60px;margin-top:20px"
            ></img>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Python">
            <img src={python} className={classes.image}></img>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.root}
        spacing={2}
        Style="margin-top:60px"
      >
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Flutter">
            <img
              src={flutter}
              className={classes.image}
              Style="height:75px;margin-top:15px"
            ></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="MySQL">
            <img src={mysql} className={classes.image}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="MongoDB">
            <img
              src={mongo}
              className={classes.image}
              Style="height:200px;margin-top:-40px"
            ></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center;margin-top:10px">
          <Tooltip title="Spring Boot">
            <img src={spring} className={classes.image}></img>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.root}
        spacing={2}
        Style="margin-top:60px"
      >
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Django">
            <img
              src={django}
              className={classes.image}
              Style="height:80px;margin-top:10px"
            ></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Java">
            <img src={java} className={classes.image}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="Go Lang">
            <img src={golang} className={classes.image} style={{borderRadius: 5}}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="AWS">
            <img src={aws} className={classes.image}></img>
          </Tooltip>
        </Grid>

      </Grid>
      <Grid
        container
        className={classes.root}
        spacing={2}
        Style="margin-top:60px"
      >
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center;margin-top:10px">
          <Tooltip title="C Programming">
            <img src={c} className={classes.image}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="PHP">
            <img src={php} className={classes.image}></img>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3} lg={3} Style="text-align:center">
          <Tooltip title="HTML , CSS , Javascript , JQuery , Bootstarp">
            <img src={all} className={classes.image}></img>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}
