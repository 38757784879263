import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyC4IS7qf6OK94gBBAsac21WxstNu97_6II",
    authDomain: "karthikdattuch.firebaseapp.com",
    databaseURL: "https://karthikdattuch.firebaseio.com",
    projectId: "karthikdattuch",
    storageBucket: "karthikdattuch.appspot.com",
    messagingSenderId: "1085485124910",
    appId: "1:1085485124910:web:a625d7767e2de7ad8f3ebf"
}

firebase.initializeApp(config);

firebase.analytics();

export default firebase;