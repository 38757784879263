import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import Grid from "@material-ui/core/Grid";
import Experience from "./Components/Experience";
import Education from "./Components/Education";
import Skills from "./Components/Skills";
import Button from "@material-ui/core/Button";
import emailjs from "emailjs-com";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile1 from "../src/StaticPages/images/pic.jpg";
import pdf from "./Assests/Sai'sResume.pdf";
import firebase from "./Firebase/Firebase";
import loader from "./loaders/threecircles.gif";
import CountUp from "react-countup";
import { Wave, Random } from "react-animated-text";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import { isMobile } from "react-device-detect";
import {FaLinkedin , FaGithub} from 'react-icons/fa';
import {AiFillCloseCircle} from 'react-icons/ai';
import cookie from "react-cookies";
import Carousel from 'react-material-ui-carousel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Paper from '@material-ui/core/Paper';
import rec1 from './Assests/Rec1.jpg';
import rec2 from './Assests/Rec2.jpg';
import rec3 from './Assests/Rec3.jpg';
import rec4 from './Assests/Rec4.jpg';
import rec5 from './Assests/Rec5.jpg';
import rec6 from './Assests/Rec6.jpg';

const publicIp = require("public-ip");

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

var htmlContent = require("./StaticPages/index.html");

function App(props) {
  const willMount = useRef(true);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [visitors, setVisitors] = useState(0);
  const [open, setOpen] = useState(false);

  var ipaddress;
   useEffect(() => {
   
(async () => {
  ipaddress = await publicIp.v4();
  //=> '46.5.21.123'
  //=> 'fe80::200:f8ff:fe21:67cf'
})();
var countRef = firebase.database().ref("visitors");
var visRef = firebase.database().ref("visitorInfo");
if (!cookie.load("visitor-count")) {
  countRef.once(
    "value",
    (snapshot) => {
      let w = snapshot.val();
      setVisitors(w[0].count + 1);
      cookie.save("visitor-count", w[0].count + 1);
      if (!isMobile) {
        setOpen(true);
      }
      countRef
        .child("0")
        .update({ count: w[0].count + 1 })
        .then(() => {
          visRef.child(w[0].count + 1).set({ ip: ipaddress }, () => {});
        })
        .catch(() => {
          console.log("failed");
        });
    },
    (err) => {
      console.log(err);
      toast.dark("Unable to disaply visitor count🙂", {
        position: "top-right",
        pauseOnHover: true,
        draggable: true,
      });
    }
  );
} else {
  var countRef = firebase.database().ref("visitors");
  countRef.once("value", (snapshot) => {
    let w = snapshot.val();
    setVisitors(w[0].count);
    if(cookie.load('visitor-count')!=w[0].count){
      cookie.save('visitor-count',w[0].count);
    }
    if (!isMobile) {
      setOpen(true);
    }
  });
}
}, []);

  /*   if(willMount.current){
    console.log("test");
    console.log(visitors);
    var countRef = firebase.database().ref('visitors');
    countRef.child('0').update({count: visitors}).then(()=>{
      console.log("updated");
    }).catch(()=>{
      console.log("failed");
    })
  }




 */
  const submit = (event) => {
    event.preventDefault();
    if (name && message && subject && email) {
      const templateParams = {
        from_name: name,
        message: message,
        subject: subject,
        to_name: "Karthik",
        from_email: email,
      };
      setLoading(true);
      emailjs
        .send(
          "service_5o4nupt",
          "template_kv6hu0t",
          templateParams,
          "user_UrSzNfk0Txv33rqJCC7uC"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            toast.success("Email has been sent successfully🎉", {
              position: "top-right",
              pauseOnHover: true,
              draggable: true,
            });
            setLoading(false);
          },
          (err) => {
            console.log("FAILED...", err);
            toast.warning(
              "Something went wrong in sending mail, Please contact personally🙂",
              {
                position: "top-right",
                pauseOnHover: true,
                draggable: true,
              }
            );
            setLoading(false);
          }
        );
    } else {
      var error = name
        ? email
          ? subject
            ? message
              ? "Done"
              : "🚀 Please enter Message!"
            : "🚀 Please enter Subject!"
          : "🚀 Please enter Email!"
        : "🚀 Please enter Name!";
      toast.dark(error, {
        position: "top-right",
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleChange = (name) => (event) => {
    if (name == "email") {
      setEmail(event.target.value);
    }
    if (name == "message") {
      setMessage(event.target.value);
    }
    if (name == "subject") {
      setSubject(event.target.value);
    }
    if (name == "name") {
      setName(event.target.value);
    }
  };

  return (
    <span>
      <Backdrop className={classes.backdrop} open={loading}>
        {/* <CircularProgress color="inherit" /> */}
        <img src={loader} Style="height:250px"></img>
      </Backdrop>
      <ToastContainer />
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <section class="about" id="about">
        <div class="max-width">
          <h2 class="title">About me</h2>
          <div class="about-content">
            <div class="column left">
              <img src={profile1} alt="" />
            </div>
            <div class="column right">
              <div class="text">
                I'm Karthik and I'm a <span class="typing-2"></span>
              </div>
              <p>
                Currently pursuing my Master's degree at University of Maryland, Baltimore County
                and worked as a Sofware Development Engineer. Member of
                R&D team, which is responsible for analysing new technologies
                for product enhancements. Developed websites using React.Js,
                Angular, Node.Js. Written services using Node.JS and Spring
                Boot. Developed several mobile apps using Flutter and
                ReactNative. Configured Express and Node as a middleware between
                React and Spring boot to authenticate request and responses
                using JWT token. Developed multiple applications using React.js,
                Node.js and MongoDB. Designed and developed UI for an large
                e-commerce application owned by the state goverment using React,
                React Redux and Material UI. Ensured responsiveness and mobile
                compatibility of all the pages on all platforms and browsers.
                Also developed few small scale applications like Tic-Tac-Toe,
                GithubUserSearch and deployed them in Heroku.
              </p>
              <a href={pdf} download>
                Download CV
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="teams" id="teams">
        <div class="max-width">
          <h2 class="title">Experience</h2>
          <Experience></Experience>
        </div>
      </section>
      <section class="skills" id="skills">
        <div class="max-width">
          <h2 class="title">Education</h2>
          <Education></Education>
        </div>
      </section>
      <section class="services" id="services">
        <div class="max-width">
          <h2 class="title">Skills</h2>
          <Skills></Skills>
        </div>
      </section>
      {/* <section class="about" id="about">
        <div class="max-width">
          <h2 class="newtitle">Work Around</h2>
          <Skills></Skills>
        </div>
      </section>
      <section class="teams" id="teams">
        <div class="max-width">
          <h2 class="newtitle">Linked In Reviews</h2>
          <Skills></Skills>
        </div>
      </section> */}
      <section className="contact" id="contact">
        {!isMobile?
        <Paper class="max-width" style={{marginBottom:100,padding:20,border:"1px solid grey",borderRadius:5}}>
        <h2 class="linked">LinkedIn Recommendations</h2>
        <Carousel
          fullHeightHover={false}    
          autoPlay={true}
        >
          <img src={rec1} style={{marginLeft:"2%",width:"100%",height:"250px",objectFit:"contain"}}></img>
          <img src={rec2} style={{marginLeft:"2%",width:"100%",height:"250px",objectFit:"contain"}}></img>
          <img src={rec3} style={{marginLeft:"0%",width:"100%",height:"250px",objectFit:"contain"}}></img>
          <img src={rec4} style={{marginLeft:"2%",width:"100%",height:"250px",objectFit:"contain"}}></img>
          <img src={rec5} style={{marginLeft:"2%",width:"98%",height:"250px",objectFit:"contain"}}></img>
          <img src={rec6} style={{marginLeft:"2%",width:"100%",height:"250px",objectFit:"contain"}}></img>
        </Carousel>
        </Paper>: null}
        <div class="max-width">
          <h2 class="title">Contact me</h2>
          <div class="contact-content">
            <div class="column left">
              <div class="text">Get in Touch</div>
              <p>Don't hesitate to send email&nbsp;🙂</p>
              <div class="icons">
                <div class="row">
                  <i class="fas fa-user"></i>
                  <div class="info">
                    <div class="head">Name</div>
                    <div class="sub-title">Karthik Dattu</div>
                  </div>
                </div>
                <div class="row">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="info">
                    <div class="head">Address</div>
                    <div class="sub-title">Maryland, USA</div>
                  </div>
                </div>
                <div class="row">
                  <i class="fas fa-phone"></i>
                  <div class="info">
                    <div class="head">Phone</div>
                    <div class="sub-title">+1 2405513921</div>
                  </div>
                </div>
                <div class="row">
                  <i class="fas fa-envelope"></i>
                  <div class="info">
                    <div class="head">Email</div>
                    <div class="sub-title">dattu.sai4@gmail.com</div>
                  </div>
                </div>
                <div class="row" Style="margin-top:50px;color:crimson !important;font-size:34px !important;">
                  <a href='https://www.linkedin.com/in/karthik-dattu-95222b12a' Style="color:#0073b1" target='_blank'><FaLinkedin fontSize="30px"></FaLinkedin></a>&nbsp;&nbsp;&nbsp;
                  <a href='https://github.com/Karthik4011' Style="color:black"  target='_blank'><FaGithub fontSize="30px"></FaGithub></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div class="column right">
              <div class="text">Message me</div>
              <form action="none">
                <div class="fields">
                  <div class="field name">
                    <input
                      type="text"
                      value={name.value}
                      onChange={handleChange("name")}
                      placeholder="Enter your Name"
                      required
                    />
                  </div>
                  <div class="field email">
                    <input
                      type="email"
                      value={email.value}
                      onChange={handleChange("email")}
                      placeholder="Enter your Email"
                      required
                    />
                  </div>
                </div>
                <div class="field">
                  <input
                    type="text"
                    value={subject.value}
                    onChange={handleChange("subject")}
                    placeholder="Subject"
                    required
                  />
                </div>
                <div class="field textarea">
                  <textarea
                    cols="30"
                    value={message.value}
                    onChange={handleChange("message")}
                    rows="10"
                    placeholder="Message.."
                    required
                  ></textarea>
                </div>
                <div class="button">
                  <button onClick={(e) => submit(e)}>Send message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <Grid container Style="height:auto;margin-bottom:20px">
          <CountUp
            start={0}
            end={visitors}
            duration={2.75}
            separator=" "
            suffix=" visitors"
            redraw={true}
            onEnd={() => console.log("Ended! 👏")}
            onStart={() => console.log("Started! 💨")}
          >
            {({ countUpRef, start }) => (
              <div>
                <span
                  ref={countUpRef}
                  Style={
                    !isMobile
                      ? "font-size:25px;margin-left:200px;margin-right:5px"
                      : "font-size:25px;margin-right:5px"
                  }
                />
                <AccessibilityNewIcon></AccessibilityNewIcon>
              </div>
            )}
          </CountUp>
        </Grid>
        <span>
          Created By <a href="#home">KarthikDattu</a> |{" "}
          <span class="far fa-copyright"></span> 2020 All rights reserved.
        </span>
      </footer>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Grid container>
          <Grid item xs={12} Style="text-align:right;margin-top:12px;margin-right:12px;font-size:20px">
            <AiFillCloseCircle Style='margin-top:12px;margin-right:12px;font-size:20px' onClick={()=>{ setOpen(false);}}></AiFillCloseCircle>
          </Grid>
          <Grid item xs={12}>
            <div Style="font-size:30px !important;padding: 30px;text-align:center;color:#4e2323de">
              <Typography Style="margin-bottom:25px;font-size:20px">
                &#127881; Hurray &#127881;
              </Typography>
              {/*           <Random
            text={visitors + " visitors for this site"}
            effect="jump"
            effectChange={2.0}
            effectDuration={0.3}
            iterations={5}
          /> */}
              <Wave
                text={visitors + " visitors for this site"}
                effect="stretch"
                effectChange={2}
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </span>
  );
}

export default App;
