import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import SchoolIcon from "@material-ui/icons/School";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TemKonyCard from "./TemKonyCard";
import AbhibusCard from "./AbhibusCard";
import MAQCard from "./MAQCard";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    minHeight: "130px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  primaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Education() {
  const classes = useStyles();

  return (
    <span>
      {!isMobile ? (
        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Master's Degree
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Computer Science
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Jan 2022 - Present
                </Typography>
              </Paper>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  University of Maryland, Baltimore County
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Maryland,USA
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Bachelors Degree
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Department of Computer Science
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  2015 - 2019
                </Typography>
              </Paper>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector className={classes.primaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Vignan's University
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Guntur,India
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Intermediate Education
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Stream : MPC
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  2013 - 2015
                </Typography>
              </Paper>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <SchoolIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Vignan Junior College
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Guntur, India
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Repeat
            </Typography>
            <Typography>Because this is the life you love!</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem> */}
        </Timeline>
      ) : (
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineOppositeContent>
         
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={0} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Master's Degree
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  University of Maryland, Baltimore County
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Computer Science
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Jan 2022 - Present
                </Typography>
                <Typography variant="subtitle2" Style="color:grey">
                  Maryland,USA
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <SchoolIcon color="secondary" />
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6" component="h1">
                Bachelors Degree
              </Typography>
              <Typography variant="subtitle1" Style="color:grey">
                Vignan's University
              </Typography>
              <Typography variant="subtitle2" Style="color:grey">
                Department of Computer Science
              </Typography>
              <Typography variant="subtitle2" Style="color:grey">
                2015 - 2019
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <SchoolIcon color="primary" />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6" component="h1">
                Secondary Education
              </Typography>
              <Typography variant="subtitle1" Style="color:grey">
                Vignan Junior College
              </Typography>
              <Typography variant="subtitle2" Style="color:grey">
                Stream : MPC
              </Typography>
              <Typography variant="subtitle2" Style="color:grey">
                2013 - 2015
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}
    </span>
  );
}
