import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Company1 from "../Assests/abhibus.jpg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import InfoIcon from '@material-ui/icons/Info';
import { isMobile } from "react-device-detect";
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "230px",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(13),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  paper: {
    backgroundColor: 'transparent',
    color: 'white'
  }
}));

export default function AbhibusCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <span>
    {!isMobile ? (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" Style="font-size:15px">
            Software Engineer
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            Style="font-size:12px"
          >
            Jul 2019 - Jun 2020
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <a href="https://www.linkedin.com/company/abhibus/" target="_blank">
            <IconButton aria-label="previous">
              <LinkedInIcon className={classes.playIcon} color="primary" />
            </IconButton>
          </a>
          <a href="https://www.abhibus.com/about" target="_blank">
            <IconButton aria-label="play/pause">
                 <InfoIcon className={classes.playIcon} color="secondary" />
            </IconButton>
          </a>
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        component="img"
        image={Company1}
        title="Live from space album cover"
      />
    </Card>): (
        <Paper elevation={0} className={classes.paper}>
          <Typography variant="h6" component="h1">
            AbhiBus Pvt.Ltd.
          </Typography>
          <Typography variant="subtitle2" Style="color:grey">
            Software Engineer
          </Typography>
          <Typography variant="subtitle2" Style="color:grey">
          Jul 2019 - Jun 2020
          </Typography>
        </Paper>
      )}
    </span>

  );
}
