import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Company1 from "../Assests/temenos-kony.jpg";
import Company2 from "../Assests/UMBC-Logo.jpeg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import InfoIcon from "@material-ui/icons/Info";
import { isMobile } from "react-device-detect";
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  paper: {
    backgroundColor: 'transparent',
    color: 'white'
  }
}));

export default function TemKonyCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <span>
      {!isMobile ? (
        <Card className={classes.root}>
          <CardMedia
            style={{width: 250}}
            component="img"
            image={Company2}
            title="Live from space album cover"
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" Style="font-size:15px">
                Graduate Assistant
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                Style="font-size:12px"
              >
                Jan 2022 - Present
              </Typography>
            </CardContent>
            <div className={classes.controls}>
              <a
                href="https://www.linkedin.com/school/university-of-maryland-baltimore-county/"
                target="_blank"
              >
                <IconButton aria-label="previous">
                  <LinkedInIcon className={classes.playIcon} color="primary" />
                </IconButton>
              </a>
              <a href="https://umbc.edu/about/" target="_blank">
                <IconButton aria-label="play/pause">
                  <InfoIcon className={classes.playIcon} color="secondary" />
                </IconButton>
              </a>
            </div>
          </div>
        </Card>
      ) : (
        <Paper elevation={0} className={classes.paper}>
          <Typography variant="h6" component="h1">
            UMBC
          </Typography>
          <Typography variant="subtitle2" Style="color:grey">
            Graduate Assistant
          </Typography>
          <Typography variant="subtitle2" Style="color:grey">
            Jan 2022 - Present
          </Typography>
        </Paper>
      )}
    </span>
  );
}
